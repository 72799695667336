
.chip--best{
    display: flex;
    align-items: center;
    height: 24px;
    background-color: #fff;
    border-radius: 6px;
    padding: 0 10px;
    font-size: 1.2rem;
    color: var(--v-primary-base);
    width: fit-content;
}
